import { createApp, ref } from 'vue';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { useWidget } from "./composables/useWidget";
import { languages } from "./localization";
import { Config } from "./models/config";

// createApp(App).mount('#app')
(window as any).initWEOnDemand = function initWEOnDemand(el: string, config: Config = null) {

  const widgetContainer = document.querySelector(el);
  
  if(widgetContainer && widgetContainer.innerHTML.trim().length == 0) {
      widgetContainer.innerHTML = `<div style='color: lightgrey; padding: 20px; background-color: white; border: 1px solid gainsboro; text-align: center;'>Loading widget..</div>`;
  }
  
  const { setupApp, appLang } = useWidget();

  setupApp(config)
      .then(() => {
        const messages = Object.assign(languages)
        const i18n = createI18n({
            legacy: false,
            locale: appLang.value,
            fallbackLocale: 'it',
            messages
        });

        createApp(App)
          .use(i18n)
          .mount(el)
      })
      .catch(err => {
          console.error("Error initializing booking widget: " + err);
          if(widgetContainer) widgetContainer.innerHTML = `<div style='color: indianred; padding: 20px; background-color: white; border: 1px solid gainsboro; text-align: center;'>Widget initialization error!</div>`;
      });

  // createApp(App).use(i18n).mount(el);
}
