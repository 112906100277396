import { BaseService } from "./BaseService";
import { CreateReqRequest } from "./requests/CreateReqRequest";
import { ApiResponse } from "./responses/ApiResponse";
import { CreateReqResponse } from "./responses/CreateReqResponse";

class RequestService extends BaseService {

    // async searchAvailabilities(request: AvailabilityRequest): Promise<AvailabilityResponse[]> {
    //     const response = await this.tryExecute(
    //         this.api.post<ApiResponse<AvailabilityResponse[]>>("reservations/availabilities", request)
    //     );

    //     return this.handleApiResponse(response.data);
    // }

    async createRequest(request: CreateReqRequest): Promise<CreateReqResponse> {
        const response = await this.tryExecute(
            this.api.post<ApiResponse<CreateReqResponse>>("ondemand/createrequest?key=" + process.env.VUE_APP_API_TOKEN, request)
        );
        
        return this.handleWEApiResponse(response.data);
    }

    // async getReservation(idReservation: number, userEmail: string): Promise<GetReservationResponse> {
    //     const response = await this.tryExecute(
    //         this.api.get<ApiResponse<GetReservationResponse>>(`reservations/${idReservation}/${userEmail}`)
    //     );

    //     return this.handleApiResponse(response.data);
    // }
}

export default new RequestService();