<template>
  <div class="we-ondemand">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js + TypeScript App"/> -->
    <OnDemandForm></OnDemandForm>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
// import HelloWorld from './components/HelloWorld.vue';
import OnDemandForm from '@/components/OnDemandForm.vue';

export default defineComponent({
  name: 'App',
  components: {
    // HelloWorld,
    OnDemandForm
  }
});
</script>

<style lang="less">
@import "dp365-we-ondemand.less";
</style>


