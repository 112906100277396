import { BaseService } from "./BaseService";
import { ApiResponse } from "./responses/ApiResponse";
import { GetExpertsResponse } from "./responses/GetExpertsResponse";
import { GetFieldsResponse } from "./responses/GetFieldsResponse";
import { WidgetInitializationResponse } from "./responses/WidgetInitializationResponse";

class LookupService extends BaseService {

  async initWidget(code: string): Promise<WidgetInitializationResponse> {
      const response = await this.tryExecute(this.api.get<ApiResponse<WidgetInitializationResponse>>("lookups/widget/" + code));

      return this.handleApiResponse(response.data);
  }

  async getFields(): Promise<GetFieldsResponse[]> {
    const response = await this.tryExecute(this.api.get<ApiResponse<GetFieldsResponse[]>>("ondemand/getfields?key=" + process.env.VUE_APP_API_TOKEN));
    
    return this.handleWEApiResponse(response.data);
  }
  
  async getExperts(): Promise<GetExpertsResponse[]> {
    const response = await this.tryExecute(this.api.get<ApiResponse<GetExpertsResponse[]>>("ondemand/getexperts?key=" + process.env.VUE_APP_API_TOKEN));
    
    return this.handleWEApiResponse(response.data);
  }    
}

export default new LookupService();