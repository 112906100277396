
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { CreateReqRequest } from "@/services/requests/CreateReqRequest";
import requestService from "@/services/RequestService";
import { useWidget } from "@/composables/useWidget";
import dayjs from "dayjs";
import LookupService from "@/services/LookupService";
import { GetFieldsResponse } from "@/services/responses/GetFieldsResponse";
import { GetExpertsResponse } from "@/services/responses/GetExpertsResponse";
import Popup from "./popup.vue";

export default defineComponent({
  components: { Popup },
  setup() {
    const { t } = useI18n();
    const loading = ref(false);
    const { fetchData, companyAndRoleVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, privacyPolicyLink, marketingPolicyLink, appLang } = useWidget();
    const hours = ref<{ hourValue: number, displayValue: string }[]>([]);
    const dateDisabled = ref(true);
    const popupResult = ref(null);
    // const validation = ref(true);
    const validationFirstName = ref(true);
    const validationLastName = ref(true);
    const validationEmail = ref(true);
    const validationPhone = ref(true);
    const validationCompany = ref(true);
    const validationRole = ref(true);

    const fields = ref<GetFieldsResponse[]>([]);
    const experts = ref<GetExpertsResponse[]>([]);

    const custType = ref('field');
    const custFirstName = ref('');
    const custLastName = ref('');
    const custEmail = ref('');
    const custPhone = ref('');
    const custCompany = ref('');
    const custRole = ref('');
    const custAsSoon = ref(true);
    const custRequiredDate = ref(dayjs());
    const custRequiredHour = ref<number>();
    const custFieldId = ref<number>();
    const custExpertId = ref<number>();
    const custNotes = ref('');
    const custPrivacyChecked = ref(false);
    const custDataConsentChecked = ref(false);
    const custMarketingChecked = ref(false);

    const showThanksMsg = ref(false);

    onMounted(async () => {
      console.log('OnDemandForm mounted!');
      console.log();

      const returnedFields = await fetchData(loading, LookupService.getFields());
      console.log(returnedFields);
      fields.value = returnedFields;

      const returnedExperts = await fetchData(loading, LookupService.getExperts());
      console.log(returnedExperts);
      experts.value = returnedExperts;

      for (let hour = 9; hour < 19; hour++) {
        hours.value.push({
          hourValue: hour,
          displayValue: `${hour}:00`
        });
      }
      custRequiredHour.value = hours.value[0].hourValue;
    });

    const titleExists = computed(() => {
        return t('common.title') != '';
    });

    const claimExists = computed(() => {
        return t('common.claim') != '';
    });

    const custRequiredDateModel = computed({
      get(): string {
        return custRequiredDate.value.format("YYYY-MM-DD");
      },
      set(newValue: string): void {
        custRequiredDate.value = dayjs(newValue);
      }
    });

    const appCulture = computed(() => {
        if(appLang.value == 'it') {
          return 'it-IT';
        } else {
          return 'en-US';
        }
    })

    const createDisabled = computed(() => (chkPrivacyVisible.value == true && custPrivacyChecked.value != true) || (chkDataConsentVisible.value == true && custDataConsentChecked.value != true) );
    // const dateDisabled = computed(() => custAsSoon.value == true );

    watch(custFirstName, (newValue) => {
      validationFirstName.value = newValue != '';
    })
    watch(custLastName, (newValue) => {
      validationLastName.value = newValue != '';
    })
    watch(custEmail, (newValue) => {
      validationEmail.value = newValue != '' && validateEmail(custEmail.value);
    })
    watch(custPhone, (newValue) => {
      validationPhone.value = newValue != '';
    })
    watch(custCompany, (newValue) => {
      validationCompany.value = newValue != '';
    })   
    watch(custRole, (newValue) => {
      validationRole.value = newValue != '';
    })         

    watch(custAsSoon, () => {
      // console.log('The new custAsSoon value is: ' + custAsSoon.value)
      dateDisabled.value = !dateDisabled.value;
      // console.log('The new dateDisabled value is: ' + dateDisabled.value)
    })

    watch(custType, (newValue) => {
      if(newValue === 'field') {
        custExpertId.value = null;
      } else {
        custFieldId.value = null;
      }
    })

    const create = async () => {

      validationFirstName.value = custFirstName.value != '';
      validationLastName.value = custLastName.value != '';
      validationPhone.value = custPhone.value != '';
      validationEmail.value = custEmail.value != '' && validateEmail(custEmail.value);
      validationCompany.value = !companyAndRoleVisible.value || custCompany.value != '';
      validationRole.value = !companyAndRoleVisible.value || custRole.value != '';

      if(validationFirstName.value == false|| validationLastName.value == false || validationPhone.value == false  || validationEmail.value == false
          || validationCompany.value == false || validationRole.value == false)
      {
        // validation.value = false;
        console.log('Validation KO');
        return;
      }

      console.log('Validation ok');

      let reqDate = custRequiredDate.value
          .hour(custRequiredHour.value)
          .minute(0)
          .second(0)
          .millisecond(0)   
          .toDate();

      if(custAsSoon.value == true) {
        reqDate = null;
      }

      const request : CreateReqRequest = {
        fieldId: custFieldId.value,
        expertId: custExpertId.value,  
        asSoonAsPossible: custAsSoon.value,
        requiredDate: reqDate,
        firstName: custFirstName.value,
        lastName: custLastName.value,
        phoneNumber: custPhone.value,
        company: custCompany.value,
        role: custRole.value,
        emailAddress: custEmail.value,
        notes: custNotes.value,
        language: appCulture.value,
        privacyChecked: chkPrivacyVisible.value ? custPrivacyChecked.value : null,
        dataConsentChecked: chkDataConsentVisible.value ? custDataConsentChecked.value : null,
        marketingChecked: chkMarketingVisible.value ? custMarketingChecked.value : null
      }
      console.log(request);
      const reqCreated = await fetchData(loading, requestService.createRequest(request))
      console.log(reqCreated);
      if(reqCreated && reqCreated.id && reqCreated.id != 0) {
        showThanksMsg.value = true;
        // popupResult.value.show(); 
      }     
    }

    const validateEmail = (email: string): boolean => {
      // prettier-ignore
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    return { t, hours, fields, experts, popupResult, validationFirstName, validationLastName, validationEmail, validationPhone,
        custCompany, custRole, validationCompany, validationRole,
        custType, custFirstName, custLastName, custEmail, custPhone, custAsSoon, custRequiredDateModel, custRequiredHour, 
        custFieldId, custExpertId, custNotes, custPrivacyChecked, custDataConsentChecked, custMarketingChecked, 
        create, createDisabled, dateDisabled, companyAndRoleVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, 
        privacyPolicyLink, marketingPolicyLink, showThanksMsg, titleExists, claimExists }
  }
});
