import en from './en.json';
import it from './it.json';

function getLang() {
  let lang = "en";

  if (navigator.languages != undefined) {
    lang = navigator.languages[0]; 
  }
  else if (navigator.language != undefined) {
    lang = navigator.language;
  }

  const langSeparatorIndex = lang.indexOf("-");
  if(langSeparatorIndex > 0) {
    lang = lang.substring(0, langSeparatorIndex).toLowerCase();
  }

  return lang;
}

// console.log(getLang())

export const defaultLocale = getLang();

export const languages = {
  en: en,
  it: it,
}