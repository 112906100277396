
import { defineComponent, ref } from "vue";
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
    props: {
        isloading: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {
        const modalInfo = ref(null);
        const modalInfoContent = ref(null);
        const showPopup = ref(false);
        onClickOutside(modalInfoContent, () => close());
        
        function getAbsolutePosition(element: any) {
            var r = { x: element.offsetLeft, y: element.offsetTop };
            if (element.parentElement && element.className != "bike-rent") {
                var tmp = getAbsolutePosition(element.offsetParent);
                r.x += tmp.x;
                r.y += tmp.y;
            }
            return r;
        }

        const show = (event: any) => {
            showPopup.value = true;

            // var el = modalInfo.value;

            // var pos = getAbsolutePosition(event.target);
            // var left = pos.x + 40;            
            // var top = pos.y - 20;

            // el.style.left = left + "px";
            // el.style.top = top + "px";

            // // check if position go outside container width
            // nextTick(() => {
            //     console.log(el.clientWidth + "x" + el.clientHeight + " in " + el.parentElement.clientWidth + "x" + el.parentElement.clientHeight);
            
            //     if(left + 360 > el.parentElement.clientWidth) {
            //         left = el.parentElement.clientWidth - 360;
            //     }
            // });            
        }

        const close = () => {
            showPopup.value = false;
        }

        return { show, close, modalInfo, modalInfoContent, showPopup }
    }
});
