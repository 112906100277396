import { ref, computed, Ref } from "vue";
import { useMediaQuery } from '@vueuse/core';
import { Config } from "@/models/config";
import { defaultLocale } from "@/localization";

const appLang = ref("");
const companyAndRoleVisible = ref(false);
const chkPrivacyVisible = ref(false);
const chkDataConsentVisible = ref(false);
const chkMarketingVisible = ref(false);
const privacyPolicyLink = ref("");
const marketingPolicyLink = ref("");

export function useWidget() {
    const loading = ref(false)
    // const appLang = computed(() => {
    //     return appCode.value && appCode.value.length > 0;
    // });

    const setupApp = async (cfg: Config) => {
        if(cfg != null) {
            console.log(cfg);

            if(cfg.language && cfg.language != '') {
              appLang.value = cfg.language;
            } else {
              appLang.value = defaultLocale;
            }

            if(cfg.companyAndRoleVisible) {
              companyAndRoleVisible.value = cfg.companyAndRoleVisible;
            }

            if(cfg.chkPrivacyVisible) {
              chkPrivacyVisible.value = cfg.chkPrivacyVisible;
            }
            if(cfg.chkDataConsentVisible) {
              chkDataConsentVisible.value = cfg.chkDataConsentVisible;
            }            
            if(cfg.chkMarketingVisible) {
              chkMarketingVisible.value = cfg.chkMarketingVisible;
            }
            if(cfg.privacyPolicyLink) {
              privacyPolicyLink.value = cfg.privacyPolicyLink;
            }
            if(cfg.marketingPolicyLink) {
              marketingPolicyLink.value = cfg.marketingPolicyLink;
            }            
            
        } else {
            throw new Error("Widget inizialization failed due to a wrong configuration object");
        }
    }

    const _displayError = (el: Element, error: string) => {
        if(el != null) {
            el.innerHTML = `<div style='margin: 0 auto; color: red; padding: 20px; margin: 20px;background-color: white'>${error}</div>`;
        }
    }

    const _displayInfo = (el: Element, info: string) => {
        if(el != null) {
            el.innerHTML = `<div style='margin: 0 auto; color: lightblue; padding: 20px; margin: 20px;background-color: white'>${info}</div>`;
        }
    }

    const fetchData = async <T> (loading: Ref<boolean>, func: Promise<T>) => {
        loading.value = true;

        try {
            return await func;
        }
        catch(err) {
            console.log("Error fetching data from server: " + err);
            return null;
        }
        finally {
            loading.value = false;
        }
    }

    const isLargeScreen = useMediaQuery('(min-width: 960px)')

    return { 
        loading, isLargeScreen, appLang, companyAndRoleVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, privacyPolicyLink, marketingPolicyLink, setupApp, fetchData
    }
}