<template>
    <div class="popup" :class="{show: showPopup}" ref="modalInfo">
        <div class="popup-content" :class="{show: showPopup}" v-if="showPopup" ref="modalInfoContent">
            <slot></slot>
            <span class="popup-close" @click="close()">
                <svg viewBox="0 0 20 20">
                    <path d="M10.185,1.417c-4.741,0-8.583,3.842-8.583,8.583c0,4.74,3.842,8.582,8.583,8.582S18.768,14.74,18.768,10C18.768,5.259,14.926,1.417,10.185,1.417 M10.185,17.68c-4.235,0-7.679-3.445-7.679-7.68c0-4.235,3.444-7.679,7.679-7.679S17.864,5.765,17.864,10C17.864,14.234,14.42,17.68,10.185,17.68 M10.824,10l2.842-2.844c0.178-0.176,0.178-0.46,0-0.637c-0.177-0.178-0.461-0.178-0.637,0l-2.844,2.841L7.341,6.52c-0.176-0.178-0.46-0.178-0.637,0c-0.178,0.176-0.178,0.461,0,0.637L9.546,10l-2.841,2.844c-0.178,0.176-0.178,0.461,0,0.637c0.178,0.178,0.459,0.178,0.637,0l2.844-2.841l2.844,2.841c0.178,0.178,0.459,0.178,0.637,0c0.178-0.176,0.178-0.461,0-0.637L10.824,10z"></path>
                </svg>
            </span>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { onClickOutside } from '@vueuse/core';

export default defineComponent({
    props: {
        isloading: {
            type: Boolean,
            default: false
        },
    },
    setup(props) {
        const modalInfo = ref(null);
        const modalInfoContent = ref(null);
        const showPopup = ref(false);
        onClickOutside(modalInfoContent, () => close());
        
        function getAbsolutePosition(element: any) {
            var r = { x: element.offsetLeft, y: element.offsetTop };
            if (element.parentElement && element.className != "bike-rent") {
                var tmp = getAbsolutePosition(element.offsetParent);
                r.x += tmp.x;
                r.y += tmp.y;
            }
            return r;
        }

        const show = (event: any) => {
            showPopup.value = true;

            // var el = modalInfo.value;

            // var pos = getAbsolutePosition(event.target);
            // var left = pos.x + 40;            
            // var top = pos.y - 20;

            // el.style.left = left + "px";
            // el.style.top = top + "px";

            // // check if position go outside container width
            // nextTick(() => {
            //     console.log(el.clientWidth + "x" + el.clientHeight + " in " + el.parentElement.clientWidth + "x" + el.parentElement.clientHeight);
            
            //     if(left + 360 > el.parentElement.clientWidth) {
            //         left = el.parentElement.clientWidth - 360;
            //     }
            // });            
        }

        const close = () => {
            showPopup.value = false;
        }

        return { show, close, modalInfo, modalInfoContent, showPopup }
    }
});
</script>