<template>
  <div class="form">
    <h1 v-if="titleExists" class="center blue bold uppercase">{{ t("common.title") }}</h1>
    <div v-if="showThanksMsg">
      <div class="thanksMessage center">{{ t("common.msgThanksFirstRow") }}</div>
      <br />
      <div class="thanksMessage center">{{ t("common.msgThanksSecondRow") }}</div>
      <br />
    </div>    
    <div v-else>
      <h2 v-if="claimExists" class="center claim">{{ t("common.claim") }}</h2>

      <!-- <div class="filter-row">
          <label>{{ t("common.dateTimeStart") }}</label>
          <div>
            <input type="date" v-model="fromModel" />
            &nbsp;
            <select v-model="fromHour">
              <option v-for="hour in hours" :key="hour.hourValue" :value="hour.hourValue">{{ hour.displayValue }}</option>
            </select>
          </div>
          <label>{{ t("common.dateTimeEnd") }}</label>
          <div>
            <input type="date" v-model="toModel" />
            &nbsp;
            <select v-model="toHour">
              <option v-for="hour in hours" :key="hour.hourValue" :value="hour.hourValue">{{ hour.displayValue }}</option>
            </select>
          </div>
      </div> -->

      <!-- <div class="filter-row">
          <label>{{ t("common.numOfAdultPaxs") }}</label>
          <input type="number" min="1" step="1" v-model="paxAdults" />
      </div>

      <div class="filter-row">
          <label>{{ t("common.numOfChildPaxs") }}</label>
          <input type="number" min="1" step="1" v-model="paxChildren" />
      </div> -->

      <div class="form-row">
          
          <div class="form-field">
            <label for="firstName">{{ t("common.firstName") }} *</label>
            <input type="text" v-model="custFirstName" class="form-input form-input-text" :class="!validationFirstName? 'has-error' : ''" id="firstName" />
            <label v-if="!validationFirstName" id="firstName-error" class="has-error" for="firstName">{{ t("common.firstNameRequired") }}</label>
          </div>        
          <div class="form-field">
            <label for="lastName">{{ t("common.lastName") }} *</label>
            <input type="text" v-model="custLastName" class="form-input form-input-text" :class="!validationLastName? 'has-error' : ''" id="lastName" />
            <label v-if="!validationLastName" id="lastName-error" class="has-error" for="lastName">{{ t("common.lastNameRequired") }}</label>
          </div>      
          <div class="form-field">
            <label for="email">{{ t("common.emailAddress") }} *</label>
            <input type="text" v-model="custEmail" class="form-input form-input-text" :class="!validationEmail? 'has-error' : ''" id="email" />
            <label v-if="!validationEmail" id="email-error" class="has-error" for="email">{{ t("common.emailAddressRequired") }}</label>
          </div>        
          <div class="form-field">
            <label for="phone">{{ t("common.phoneNumber") }} *</label>
            <input type="text" v-model="custPhone" class="form-input form-input-text" :class="!validationPhone? 'has-error' : ''" id="phone" />
            <label v-if="!validationPhone" id="phone-error" class="has-error" for="phone">{{ t("common.phoneNumberRequired") }}</label>
          </div>

          <div class="form-field" v-if="companyAndRoleVisible">
            <label for="company">{{ t("common.company") }} *</label>
            <input type="text" v-model="custCompany" class="form-input form-input-text" :class="!validationCompany? 'has-error' : ''" id="company" />
            <label v-if="!validationCompany" id="company-error" class="has-error" for="company">{{ t("common.companyRequired") }}</label>
          </div>
          <div class="form-field" v-if="companyAndRoleVisible">
            <label for="role">{{ t("common.role") }} *</label>
            <input type="text" v-model="custRole" class="form-input form-input-text" :class="!validationRole? 'has-error' : ''" id="role" />
            <label v-if="!validationRole" id="role-error" class="has-error" for="role">{{ t("common.roleRequired") }}</label>            
          </div>          

          <label>{{ t("common.labelType") }}</label>
          <div style="float:left;width:50%">
            <div class="form-field">
              <label class="privacyregistration">
                <input class="we-radio form-input" type="radio" id="optType" name="optType" value="field" v-model="custType" >{{ t("common.labelTypeField") }}
              </label>
            </div>
            <div class="form-field">
              <select class="form-input form-input-data" v-model="custFieldId" :disabled="custType === 'expert'">
                <option v-for="f in fields" :key="f.id" :value="f.id">{{ f.name }}</option>
              </select>
            </div>           
          </div>
          <div style="float:right;width:50%">
            <div class="form-field">
              <label class="privacyregistration">
                <input class="we-radio form-input" type="radio" id="optType" name="optType" value="expert" v-model="custType" >{{ t("common.labelTypeExpert") }}
              </label>
            </div>
            <div class="form-field">
              <select class="form-input form-input-data" v-model="custExpertId" :disabled="custType === 'field'">
                <option v-for="e in experts" :key="e.id" :value="e.id">{{ e.cognome + ' ' + e.nome }}</option>
              </select>
            </div>          
          </div>
          <div style="clear:both;width:100%;padding-top:10px">
            <!-- <label class="no-bold" style="margin-top:0;">(Sarai comunque contattato per concordare nel dettaglio data e ora)</label> -->
          </div>        
          
          <label>{{ t("common.labelWhen") }}</label>
          <div style="float:left;width:50%">
            <div class="form-field">
              <label class="privacyregistration">
                <input class="we-radio form-input" type="radio" id="optAsSoon" name="optAsSoon" value="true" v-model="custAsSoon" >{{ t("common.labelWhenAsSoon") }}
              </label>
            </div>
          </div>
          <div style="float:right;width:50%">
            <div class="form-field">
              <label class="privacyregistration">
                <input class="we-radio form-input" type="radio" id="optAsSoon" name="optAsSoon" value="false" v-model="custAsSoon" >{{ t("common.labelWhenDate") }}
              </label>
            </div>
            <div class="form-field">
              <input type="date" class="form-input form-input-data" v-model="custRequiredDateModel" :disabled="dateDisabled" />
              &nbsp;
              <select class="form-input form-input-data" v-model="custRequiredHour" :disabled="dateDisabled">
                <option v-for="hour in hours" :key="hour.hourValue" :value="hour.hourValue">{{ hour.displayValue }}</option>
              </select>
            </div>          
          </div>
          <div style="clear:both;width:100%;padding-top:10px">
            <label class="no-bold" style="margin-top:0;">{{ t("common.labelWeWillContactYou") }}</label>
          </div>

          <div>
            <div class="form-field">
              <label>{{ t("common.notes") }}</label>
              <textarea v-model="custNotes" rows="4" class="form-input form-input-textarea"  />
            </div>
          </div>

          <div class="form-field" v-if="chkPrivacyVisible">
              <label class="privacyregistration">
                <input class="we-checkbox form-input form-input-chk" type="checkbox" v-model="custPrivacyChecked" id="Privacy1" name="Privacy1"> {{ t("common.privacyText") }}<a :href="privacyPolicyLink" target="_blank" >{{ t("common.privacyLink") }}</a>
              </label>
          </div>
          <div class="form-field" v-if="chkDataConsentVisible">
              <label class="privacyregistration">
                <input class="we-checkbox form-input form-input-chk" type="checkbox" v-model="custDataConsentChecked" id="Privacy3" name="Privacy3"> {{ t("common.dataConsentText") }}
              </label>
          </div>          
          <div class="form-field" v-if="chkMarketingVisible">
            <label class="privacyregistration">
              <input class="we-checkbox form-input form-input-chk" type="checkbox" v-model="custMarketingChecked" id="Privacy2" name="Privacy2"> {{ t("common.marketingText") }} (<a :href="marketingPolicyLink" target="_blank" >{{ t("common.marketingLink") }}</a>)
            </label>
          </div>        
      </div>

      <div class="center">
          <button class="btn white bgmain bold" @click="create" :disabled="createDisabled">{{ t("common.btnCreate") }}</button>
      </div>
    </div>
  </div>

  <Popup ref="popupResult">
    PROVA POPUP
  </Popup>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from 'vue-i18n';
import { CreateReqRequest } from "@/services/requests/CreateReqRequest";
import requestService from "@/services/RequestService";
import { useWidget } from "@/composables/useWidget";
import dayjs from "dayjs";
import LookupService from "@/services/LookupService";
import { GetFieldsResponse } from "@/services/responses/GetFieldsResponse";
import { GetExpertsResponse } from "@/services/responses/GetExpertsResponse";
import Popup from "./popup.vue";

export default defineComponent({
  components: { Popup },
  setup() {
    const { t } = useI18n();
    const loading = ref(false);
    const { fetchData, companyAndRoleVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, privacyPolicyLink, marketingPolicyLink, appLang } = useWidget();
    const hours = ref<{ hourValue: number, displayValue: string }[]>([]);
    const dateDisabled = ref(true);
    const popupResult = ref(null);
    // const validation = ref(true);
    const validationFirstName = ref(true);
    const validationLastName = ref(true);
    const validationEmail = ref(true);
    const validationPhone = ref(true);
    const validationCompany = ref(true);
    const validationRole = ref(true);

    const fields = ref<GetFieldsResponse[]>([]);
    const experts = ref<GetExpertsResponse[]>([]);

    const custType = ref('field');
    const custFirstName = ref('');
    const custLastName = ref('');
    const custEmail = ref('');
    const custPhone = ref('');
    const custCompany = ref('');
    const custRole = ref('');
    const custAsSoon = ref(true);
    const custRequiredDate = ref(dayjs());
    const custRequiredHour = ref<number>();
    const custFieldId = ref<number>();
    const custExpertId = ref<number>();
    const custNotes = ref('');
    const custPrivacyChecked = ref(false);
    const custDataConsentChecked = ref(false);
    const custMarketingChecked = ref(false);

    const showThanksMsg = ref(false);

    onMounted(async () => {
      console.log('OnDemandForm mounted!');
      console.log();

      const returnedFields = await fetchData(loading, LookupService.getFields());
      console.log(returnedFields);
      fields.value = returnedFields;

      const returnedExperts = await fetchData(loading, LookupService.getExperts());
      console.log(returnedExperts);
      experts.value = returnedExperts;

      for (let hour = 9; hour < 19; hour++) {
        hours.value.push({
          hourValue: hour,
          displayValue: `${hour}:00`
        });
      }
      custRequiredHour.value = hours.value[0].hourValue;
    });

    const titleExists = computed(() => {
        return t('common.title') != '';
    });

    const claimExists = computed(() => {
        return t('common.claim') != '';
    });

    const custRequiredDateModel = computed({
      get(): string {
        return custRequiredDate.value.format("YYYY-MM-DD");
      },
      set(newValue: string): void {
        custRequiredDate.value = dayjs(newValue);
      }
    });

    const appCulture = computed(() => {
        if(appLang.value == 'it') {
          return 'it-IT';
        } else {
          return 'en-US';
        }
    })

    const createDisabled = computed(() => (chkPrivacyVisible.value == true && custPrivacyChecked.value != true) || (chkDataConsentVisible.value == true && custDataConsentChecked.value != true) );
    // const dateDisabled = computed(() => custAsSoon.value == true );

    watch(custFirstName, (newValue) => {
      validationFirstName.value = newValue != '';
    })
    watch(custLastName, (newValue) => {
      validationLastName.value = newValue != '';
    })
    watch(custEmail, (newValue) => {
      validationEmail.value = newValue != '' && validateEmail(custEmail.value);
    })
    watch(custPhone, (newValue) => {
      validationPhone.value = newValue != '';
    })
    watch(custCompany, (newValue) => {
      validationCompany.value = newValue != '';
    })   
    watch(custRole, (newValue) => {
      validationRole.value = newValue != '';
    })         

    watch(custAsSoon, () => {
      // console.log('The new custAsSoon value is: ' + custAsSoon.value)
      dateDisabled.value = !dateDisabled.value;
      // console.log('The new dateDisabled value is: ' + dateDisabled.value)
    })

    watch(custType, (newValue) => {
      if(newValue === 'field') {
        custExpertId.value = null;
      } else {
        custFieldId.value = null;
      }
    })

    const create = async () => {

      validationFirstName.value = custFirstName.value != '';
      validationLastName.value = custLastName.value != '';
      validationPhone.value = custPhone.value != '';
      validationEmail.value = custEmail.value != '' && validateEmail(custEmail.value);
      validationCompany.value = !companyAndRoleVisible.value || custCompany.value != '';
      validationRole.value = !companyAndRoleVisible.value || custRole.value != '';

      if(validationFirstName.value == false|| validationLastName.value == false || validationPhone.value == false  || validationEmail.value == false
          || validationCompany.value == false || validationRole.value == false)
      {
        // validation.value = false;
        console.log('Validation KO');
        return;
      }

      console.log('Validation ok');

      let reqDate = custRequiredDate.value
          .hour(custRequiredHour.value)
          .minute(0)
          .second(0)
          .millisecond(0)   
          .toDate();

      if(custAsSoon.value == true) {
        reqDate = null;
      }

      const request : CreateReqRequest = {
        fieldId: custFieldId.value,
        expertId: custExpertId.value,  
        asSoonAsPossible: custAsSoon.value,
        requiredDate: reqDate,
        firstName: custFirstName.value,
        lastName: custLastName.value,
        phoneNumber: custPhone.value,
        company: custCompany.value,
        role: custRole.value,
        emailAddress: custEmail.value,
        notes: custNotes.value,
        language: appCulture.value,
        privacyChecked: chkPrivacyVisible.value ? custPrivacyChecked.value : null,
        dataConsentChecked: chkDataConsentVisible.value ? custDataConsentChecked.value : null,
        marketingChecked: chkMarketingVisible.value ? custMarketingChecked.value : null
      }
      console.log(request);
      const reqCreated = await fetchData(loading, requestService.createRequest(request))
      console.log(reqCreated);
      if(reqCreated && reqCreated.id && reqCreated.id != 0) {
        showThanksMsg.value = true;
        // popupResult.value.show(); 
      }     
    }

    const validateEmail = (email: string): boolean => {
      // prettier-ignore
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }

    return { t, hours, fields, experts, popupResult, validationFirstName, validationLastName, validationEmail, validationPhone,
        custCompany, custRole, validationCompany, validationRole,
        custType, custFirstName, custLastName, custEmail, custPhone, custAsSoon, custRequiredDateModel, custRequiredHour, 
        custFieldId, custExpertId, custNotes, custPrivacyChecked, custDataConsentChecked, custMarketingChecked, 
        create, createDisabled, dateDisabled, companyAndRoleVisible, chkPrivacyVisible, chkDataConsentVisible, chkMarketingVisible, 
        privacyPolicyLink, marketingPolicyLink, showThanksMsg, titleExists, claimExists }
  }
});
</script>