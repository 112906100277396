import axios from 'axios';
import qs from 'qs';
import { ApiResponse } from './responses/ApiResponse';

const _api = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL, //"/frontend/booking/",
    headers: { "Content-type": "application/json" },
    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
});

export class BaseService {

    get api() { return _api }

    handleApiResponse<T>(response: ApiResponse<T>) : T {
      if(!response.success) {
          //Toastr.warning("Errore nella richiesta al server.", response.messages);
          // TODO
          console.warn("Server response valid but request unsuccesful.." + JSON.stringify(response.messages));
      }

      return response.data;
    }

    handleWEApiResponse<T>(response: ApiResponse<T>) : T {
      if(response.errorCode != 0) {
          //Toastr.warning("Errore nella richiesta al server.", response.messages);
          // TODO
          console.warn("Server response valid but request unsuccesful.." + JSON.stringify(response.messages));
      }

      return response.result.result ? response.result.result : response.result.items;
    }

    tryExecute = async <T> (func: Promise<T>) : Promise<T | null> => {
        try {
            const result = await func;
            return result;
        }
        catch(err) {
            console.error("Server communication error.. " + err);
            return null;
        }
    }
}