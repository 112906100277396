
import { defineComponent } from 'vue';
// import HelloWorld from './components/HelloWorld.vue';
import OnDemandForm from '@/components/OnDemandForm.vue';

export default defineComponent({
  name: 'App',
  components: {
    // HelloWorld,
    OnDemandForm
  }
});
